<template>
  <div>
    <q-form ref="editForm">
      <c-card title="기본정보" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-plant
              required
              :disabled="disabled"
              :editable="editable&&(requestEditable||!isOld)"
              type="edit"
              name="plantCd"
              v-model="fireFightingIssueCheck.plantCd" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-datepicker
              required
              :disabled="disabled"
              :editable="editable&&(requestEditable||!isOld)"
              default="today"
              type="month"
              name="yearmonth"
              label="점검년월"
              v-model="fireFightingIssueCheck.yearmonth"
              @datachange="checkNameWrite" />
          </div>
          <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2">
            <c-dept 
              required
              :disabled="disabled"
              :editable="editable&&(requestEditable||!isOld)"
              type="edit" 
              label="점검부서" 
              name="deptCd" 
              v-model="fireFightingIssueCheck.deptCd"
              @datachange="(deptCd, deptName) => checkNameWrite('D', deptName)" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-text
              required
              :disabled="disabled"
              :editable="editable"
              label="점검명" 
              name="issueCheckName" 
              v-model="fireFightingIssueCheck.issueCheckName" />
          </div>
          <div v-if="isOld&&fireFightingIssueCheck.sopFireFightingIssueStepCd !== 'FFI0000001'" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-textarea
              :disabled="disabled"
              :editable="editable && confirmEditable"
              :rows="1"
              label="이슈종합내용" 
              name="issueContents" 
              v-model="fireFightingIssueCheck.issueContents" />
          </div>
          <div v-if="isOld&&fireFightingIssueCheck.sopFireFightingIssueStepCd !== 'FFI0000001'" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-textarea
              :disabled="disabled"
              :editable="editable && completeEditable"
              :rows="1"
              label="검토내용" 
              name="confirmContents" 
              v-model="fireFightingIssueCheck.confirmContents" />
          </div>
        </template>
      </c-card>
      <c-table
        ref="table"
        class="q-mt-sm"
        :title="resultTableTitle"
        :columns="grid.columns"
        gridHeightAuto
        :data="fireFightingIssueCheck.targets"
        :isTitle="true"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :editable="editable&&!disabled"
        selection="multiple"
        rowKey="sopFireFightingIssueCheckTargetId"
        @linkClick="linkClick"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn 
              v-if="editable&&(requestEditable || !isOld)" 
              :showLoading="false" 
              label="추가" 
              icon="add" 
              @btnClicked="addTarget" />
            <c-btn 
              v-if="editable&&(requestEditable || !isOld)" 
              :disabled="!fireFightingIssueCheck.targets || fireFightingIssueCheck.targets.length === 0"
              label="제외" 
              icon="remove" 
              @btnClicked="deleteTarget" />
          </q-btn-group>
        </template>
      </c-table>
    </q-form>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn
            v-show="editable&&completeEditable&&!disabled"
            flat
            :url="completeUrl"
            :isSubmit="isComplete"
            :param="fireFightingIssueCheck"
            mappingType="PUT"
            label="완료"
            icon="check"
            @beforeAction="completeInfo"
            @btnCallback="completeCallback" />
          <c-btn
            v-show="editable&&confirmEditable&&!disabled"
            flat
            :url="confirmUrl"
            :isSubmit="isConfirm"
            :param="fireFightingIssueCheck"
            mappingType="PUT"
            label="검토요청"
            icon="check"
            @beforeAction="confirmInfo"
            @btnCallback="confirmCallback" />
          <c-btn
            v-show="editable&&isOld&&requestEditable&&!disabled"
            flat
            :url="requestUrl"
            :isSubmit="isRequest"
            :param="fireFightingIssueCheck"
            mappingType="PUT"
            label="점검요청"
            icon="check"
            @beforeAction="requestInfo"
            @btnCallback="requestCallback" />
          <c-btn
            v-show="editable&&!disabled"
            flat
            :url="saveUrl"
            :isSubmit="isSave"
            :param="fireFightingIssueCheck"
            :mappingType="mappingType"
            label="저장"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" />
          <c-btn
            v-show="editable&&requestEditable&&!disabled"
            flat
            label="삭제"
            icon="delete_forever"
            @btnClicked="deleteInfo" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingIssueCheckDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingIssueCheckId: '',
        // QR 코드로 들어온 경우
        sopFireFightingId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      fireFightingIssueCheck: {
        sopFireFightingIssueCheckId: '',  // 소방 이슈점검 일련번호
        plantCd: null,  // 사업장코드
        sopFireFightingIssueStepCd: '',  // 이슈점검 진행상태
        yearmonth: '',  // 점검년월
        deptCd: '',  // 점검부서
        issueCheckName: '',  // 점검명
        issueContents: '',  // 이슈사항
        confirmContents: '',  // 검토사항
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        targets: [],
        deleteTargets: [],
      },
      grid: {
        columns: [
          {
            name: 'fireFightingName',
            field: 'fireFightingName',
            label: '소방설비',
            align: 'left',
            sortable: true,
            style: 'width: 50%',
            type: 'link',
          },
          {
            name: 'sopFireFightingTypeName',
            field: 'sopFireFightingTypeName',
            label: '유형',
            align: 'center',
            sortable: true,
            style: 'width: 50%',
          },
        ],
        height: '580px'
      },
      mappingType: 'PUT',
      editable: true,
      isSave: false,
      isRequest: false,
      isConfirm: false,
      isComplete: false,
      detailUrl: '',
      saveUrl: '',
      requestUrl: '',
      confirmUrl: '',
      completeUrl: '',
      deleteUrl: '',
      popupOptions: {
        isFull: false,
        width: '100%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = this.contentHeight - 150;
      if (height < 650) {
        height = 650
      }
      this.grid.height = String(height) + 'px';
    }
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.sopFireFightingIssueCheckId)
    },
    requestEditable() {
      return this.fireFightingIssueCheck.sopFireFightingIssueStepCd === 'FFI0000001'
        && this.fireFightingIssueCheck.regUserId === this.$store.getters.user.userId
    },
    confirmEditable() {
      return this.isOld
        && this.fireFightingIssueCheck.sopFireFightingIssueStepCd === 'FFI0000005'
        && (this.fireFightingIssueCheck.deptCd === this.$store.getters.user.deptCd
          || this.fireFightingIssueCheck.regUserId === this.$store.getters.user.userId
        )
    },
    completeEditable() {
      return this.isOld
        && this.fireFightingIssueCheck.sopFireFightingIssueStepCd === 'FFI0000010'
        && this.fireFightingIssueCheck.regUserId === this.$store.getters.user.userId
    },
    disabled() {
      return this.fireFightingIssueCheck.sopFireFightingIssueStepCd === 'FFI0000015'
    },
    resultTableTitle() {
      return !this.isOld || this.fireFightingIssueCheck.sopFireFightingIssueStepCd === 'FFI0000001' ? '평가대상 목록' : '평가대상 점검결과 목록'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.fft.fireFightingIssueCheck.get.url
      this.saveUrl = transactionConfig.fft.fireFightingIssueCheck.update.url
      this.requestUrl = transactionConfig.fft.fireFightingIssueCheck.request.url
      this.confirmUrl = transactionConfig.fft.fireFightingIssueCheck.confirm.url
      this.completeUrl = transactionConfig.fft.fireFightingIssueCheck.complete.url
      this.deleteUrl = transactionConfig.fft.fireFightingIssueCheck.delete.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopFireFightingIssueCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.fireFightingIssueCheck, _result.data)
          
          this.$emit('setRegInfo', _result.data)

          if (this.popupParam.sopFireFightingId) {
            // QR 코드로 들어온 경우 점검 결과 팝업 open
            let target = this.$_.find(this.fireFightingIssueCheck.targets, { sopFireFightingId: this.popupParam.sopFireFightingId })
            this.linkClick(target)
          }
        },);
      } else {
        // nothing..
      }
    },
    checkNameWrite(flag, deptName) {
      if (flag === 'D') this.fireFightingIssueCheck.deptName = deptName
      if (this.fireFightingIssueCheck.yearmonth && this.fireFightingIssueCheck.deptCd) {
        this.$set(this.fireFightingIssueCheck, 'issueCheckName', `${this.fireFightingIssueCheck.yearmonth} ${this.fireFightingIssueCheck.deptName} 소방 이슈점검`)
      }
    },
    linkClick(row) {
      this.popupOptions.title = `[${row.fireFightingName} / ${row.sopFireFightingTypeName}] 소방설비 이슈점검`;
      this.popupOptions.param = {
        sopFireFightingIssueCheckTargetId: row ? row.sopFireFightingIssueCheckTargetId : '',
      };
      this.popupOptions.target = () => import(`${'./fireFightingIssueCheckTarget.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    addTarget() {
      this.popupOptions.title = '소방설비 검색'; // 소방설비 검색
      this.popupOptions.param = {
        type: 'multiple',
        isIssue: true,
      };
      this.popupOptions.target = () => import(`${'@/pages/fft/fireFightingPop'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeFireFightingPopup;
    },
    closeFireFightingPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.fireFightingIssueCheck.targets, { sopFireFightingId: item.sopFireFightingId }) === -1) {
            this.fireFightingIssueCheck.targets.push({
              sopFireFightingIssueCheckTargetId: uid(),  // 소방 이슈점검 대상 일련번호
              sopFireFightingIssueCheckId: this.popupParam.sopFireFightingIssueCheckId,  // 소방 이슈점검 일련번호
              sopFireFightingId: item.sopFireFightingId,  // 소방설비 일련번호
              fireFightingName: item.fireFightingName,
              sopFireFightingTypeName: item.sopFireFightingTypeName,
              checkDate: '',  // 점검일
              sopFireFightingIssueResultCd: null,  // 이슈점검결과
              issueContents: '',  // 이슈사항
              regUserId: this.$store.getters.user.userId,  // 등록자
              editFlag: 'C'
            })
          }
        })
      }
    },
    deleteTarget() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '선택된 항목이 없습니다.', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.fireFightingIssueCheck.deleteTargets) this.fireFightingIssueCheck.deleteTargets = []
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.fireFightingIssueCheck.deleteTargets, { sopFireFightingIssueCheckTargetId: item.sopFireFightingIssueCheckTargetId }) === -1
            && item.editFlag !== 'C') {
              this.fireFightingIssueCheck.deleteTargets.push(item)
            }
          this.fireFightingIssueCheck.targets = this.$_.reject(this.fireFightingIssueCheck.targets, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.fft.fireFightingIssueCheck.update.url
        this.mappingType = 'PUT'
      } else {
        this.saveUrl = transactionConfig.fft.fireFightingIssueCheck.insert.url
        this.mappingType = 'POST'
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.fireFightingIssueCheck.regUserId = this.$store.getters.user.userId;
              this.fireFightingIssueCheck.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.sopFireFightingIssueCheckId = result.data
      this.getDetail();
    },
    requestInfo() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result && this.$comm.validTable(this.resultTableColumn, this.fireFightingIssueCheck.targets)) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '점검요청하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.fireFightingIssueCheck.chgUserId = this.$store.getters.user.userId;

              this.isRequest = !this.isRequest;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    requestCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    confirmInfo() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result && this.$comm.validTable(this.resultTableColumn, this.fireFightingIssueCheck.targets)) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '검토요청하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.fireFightingIssueCheck.chgUserId = this.$store.getters.user.userId;

              this.isConfirm = !this.isConfirm;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    confirmCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    completeInfo() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result && this.$comm.validTable(this.resultTableColumn, this.fireFightingIssueCheck.targets)) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '완료하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.fireFightingIssueCheck.chgUserId = this.$store.getters.user.userId;

              this.isComplete = !this.isComplete;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    deleteInfo() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopFireFightingIssueCheckId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>